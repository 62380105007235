<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      {{ 'ویرایش "' + title + '"' }}
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="submit">
        <v-form-base
          :col="{ cols: 12, md: 6 }"
          :model="model"
          :schema="schema"
        />

        <v-row align="center" justify="end" class="mb-3">
          <v-btn
            class="mx-4"
            dark
            color="success"
            type="submit"
            :loading="loading"
          >
            ویرایش گویندگی
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MySnackbar from "@/components/MySnackbar";
import VFormBase from "vuetify-form-base";
import { getErrorText } from "@/constants/GlobalConstants";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  components: {
    VFormBase,
    MySnackbar,
  },
  created() {
    this.getData();
    this.getSellers();
  },

  data() {
    return {
      id: this.$route.params.id,
      loading: false,
      title: "",
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("edit") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      model: {
        title: "",
        // excerpt: "",
        // full_text: "",
        status: 1,
        user_id: "",
        voice_id: "",
      },
      schema: {
        title: {
          type: "CustomInput",
          label: "عنوان",
          rules: [Constants.rules.required],
          required: true,
        },
        user_id: {
          type: "CustomInput",
          inputType: "autocomplete",
          items: [],
          itemValue: "id",
          itemText: "value",
          label: "کاربر",
          rules: [Constants.rules.required],
          loading: true,
          required: true,
        },
        // excerpt: {
        //   type: "CustomInput",
        //   label: "توضیحات مختصر",
        //   required: true,
        // },
        // full_text: {
        //   type: "MyTinyMCE",
        //   label: "توضیحات",
        //   rules: [Constants.rules.required],
        //   required: true,
        //   col: { cols: 12 },
        // },
        status: {
          type: "CustomInput",
          label: "وضعیت",
          inputType: "select",
          items: [
            { id: 1, value: "در حال بررسی" },
            { id: 2, value: "تایید شده" },
            { id: 3, value: "رد شده" },
          ],
          itemValue: "id",
          itemText: "value",
          rules: [Constants.rules.required],
          required: true,
        },
        status_desc: {
          type: "CustomInput",
          inputType: "textarea",
          solo: true,
          flat: true,
          label: "توضیحات وضعیت (رد/تایید)",
          backgroundColor: "grey lighten-2",
          class: "required rounded-lg",
        },
        voice_id: {
          type: "MyFilePond",
          title: "صوت",
          rules: [Constants.rules.required],
          required: true,
          fileTypes: "audio/*",
          maxFileSize: "300MB",
          col: { cols: 12 },
        },
      },
    };
  },

  methods: {
    getData() {
      this.loading = true;
      MyAxios.get("/podcasts/index/" + this.id, {
        params: {
          noPaginate: true,
          with: "voice",
        },
      })
        .then((response) => {
          console.log(response.data);
          this.title = response.data[0].title;
          formGetPrepare(response, this.model);
          this.schema.voice_id.files = response.data[0].voice;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    getSellers() {
      this.loading = true;
      MyAxios.get("/user/list/vendor", {
        params: {
          noPaginate: true,
        },
      })
        .then((response) => {
          this.schema.user_id = {
            ...this.schema.user_id,
            loading: false,
            items: response.data.map((val) => ({
              id: val.id,
              value: val.first_name + " " + val.last_name,
            })),
          };
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = formPostPrepare(this.model);
        MyAxios.post(`podcasts/${this.id}/update`, formData)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: "گویندگی با موفقیت ویرایش شد",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: getErrorText(error),
            });
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
  },
};
</script>

<style></style>
